import React from 'react'
import Header from '../inc/Navbar'
import AddDistributor from '../AddDistributor';
import ScrollToTop from "react-scroll-to-top"
function Distributorpage() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        
        <ScrollToTop smooth top="100" color="Gray" />

        <div className="p-3 mb-2 bg-light text-white">
        <br/>
        <br/>
                            <AddDistributor/>  
                            </div>  

    </div>
  )
}

export default Distributorpage