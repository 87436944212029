import React from "react";
import Navbar from "../inc/Navbar";
import {Link} from "react-router-dom";
import Distributorpages from "../images/DISTRIBUTORPAGE.jpg";
import Distributorpages2 from "../images/DISTRIBUTORPAGE2.jpg";
import Skill from "../images/skill.jpg";
import Experience from "../images/experience.jpg";
import Marketing from "../images/marketing.jpg";
import Earning from "../images/earning.jpg";
import AddDistributor from '../AddDistributor';
import ScrollToTop from "react-scroll-to-top";

function Distributor (){
    return(
        <>
<Navbar/>
<br/>
<br/>
<br/>
<br/>
<br/>
<ScrollToTop smooth top="100" color="Darkblue" />
<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
   
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
        <Link to="/Distributor-apply">
      <img src={Distributorpages} class="d-block w-100" alt="Distributorpages"/>
      
      <div class="carousel-caption d-none d-md-block">
        <h5>Low Investment</h5>
        <p>Best React Js Website Development & Design in West Bengal</p>
      </div>
      </Link>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
        <Link to="/Distributor-apply">
      <img src={Distributorpages2} class="d-block w-100" alt="..."/>
      <div class="carousel-caption d-none d-md-block">
        <h5>Distributor Opportunity</h5>
        <p>Best React Js Website Development & Design in West Bengal</p>
       
      </div>
      </Link>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
<br/>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-6" >
            <h2  style={{color: "Darkblue"}} className="card-text mb-3"> <b>Web Development Distributor of MR. INDIAN CODER in your City</b></h2>
            <h5  style={{color: "Gray"}} className="card-text mb-3 texts">Start your very own website designing company without having liability of a technical team and developing any websites. So set your
            goal as high as you want. </h5>
            <h5  style={{color: "Gray"}} className="card-text mb-4 texts">MR. INDIAN CODER is an awarded best web development & designing company in India, headquartered in Haydrabad. We offer high quality websites with our innovative and modern approach for our client, to ensure superb promotion on Google. 
            When you join us, You will join thousands of happy distributor from all over India</h5>
                                <h5  style={{color: "Gray"}} className="card-text mb-3 texts">The Vision of MR. INDIAN CODER is  to deliver our unique website
                                 developemnt blend and value to each corner of the  world. Distributor partners of MR. INDIAN CODER are enjoying <b>huge margins </b> and impeccable growth every year. It's easy to get started with tested, tried and high_ROI driven business models. Now currently the demand
                                  for creating websites in 2022 is increasing. The industry has <b>huge growth </b>potential.</h5>
        

                               
                                
                               
                                <div className="d-flex justify-content-between align-items-center">
                              
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6" >
                        
                
                         
                                
                                <div className="d-flex justify-content-between align-items-center">
                                <div className="card mb-4 me-3 border-white box shadow">
                            <img src={Skill} className="card-img-top"   alt="Skill" />
                            <div class="card-body">
                              <center>
    <h5 style={{color: "Gray"}}className="card-title">No Coding Knowledge Required</h5>
    </center>
                                            </div>
                         
                         
                            </div>
                            <div className="card mb-4 border-white box shadow">
                            <img src={Experience} className="card-img-top"   alt="Skill" />
                            <div class="card-body">
                            <center>
    <h5 style={{color: "Gray"}}className="card-title"> No Experience Necessary Need</h5>
    </center>
                                            </div>
                         
                         
                            </div>
                            
                          
                            </div>
                           
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="card mb-4 me-3 border-white box shadow">
                            <img src={Marketing} className="card-img-top"   alt="Marketing" />
                            <div class="card-body">
                            <center>
    <h5 style={{color: "Gray"}}className="card-title">Sell Website Packages to Businesses</h5>
    </center>
                                            </div>
                         
                         
                            </div>
                            <div className="card mb-4 me-0 border-white box shadow">
                            <img src={Earning} className="card-img-top"   alt="Earning" />
                            <div class="card-body">
                            <center>
    <h5 style={{color: "Gray"}}className="card-title">Unlimited Earning Potential</h5>
    </center>
                                            </div>
                         </div>
                         </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <br/>
                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
            <h5 style={{color: "Darkblue"}}className="card-title">Know More About <b> MR. INDIAN CODER</b></h5>
           
                         </div>
        
            
              </div>
            </div>
            <div className="album py-0">
        
        <div className="container">

            <div className="row ">
       
            <div className="col-md-5" >
            <div className="d-flex justify-content-between align-items-center">
              <a href="about">
            <button className="buttonbookfranchise button2"> About Us</button>
            </a>
                               
                         
                              
                                <a href="our-development" >
            <button className="buttonbookfranchise button2"> Our Best Work</button>
            </a> 
            
                               
                        
                            </div>
                               
                            </div>
                          
                            <div className="d-flex justify-content-between align-items-center">
                           


                
                         
                            </div>
                            </div>
                            </div>
                            </div>   
                      <br/>
                      <div className="p-3 mb-2 bg-light text-white">
                            <AddDistributor/>  
                            <br/>
                            </div>  
                           
          <hr/>
        
        </>
    );
}

export default Distributor;